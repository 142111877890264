@import url("https://fonts.googleapis.com/css2?family=Cairo&family=Roboto&display=swap");
@import url("leaflet/dist/leaflet.css");

html {
  font-family: "Cairo", sans-serif;
}

.filepond--item {
  width: calc(33.33% - 0.5em);
  height: 100px;
  min-height: 80px;
  margin: 0.25em;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  background: #fff;
}

.MuiPickersCalendarHeader-root,
.MuiPickersCalendarHeader-root .MuiTypography-root {
  direction: rtl !important;
}

.MuiTablePagination-selectLabel {
  display: none;
}

.leaflet-container {
  width: 100%;
  height: 800px;
}

.leaflet-control-attribution {
  display: none;
}
